<template>
	<div class="wscn-http404-container">
		<div class="wscn-http404">
			<div class="pic-404">
				<img class="pic-404__parent" src="@/assets/img/404/404.png" alt="404">
			</div>
			<div class="bullshit">
				<div class="bullshit__info">抱歉，您访问的页面不存在</div>
				<a href="/" class="bullshit__return-home">去首页</a>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Page404',
	computed: {
		message() {
			return 'The webmaster said that you can not enter this page...'
		}
	}
}
</script>

<style lang="less" scoped>
.wscn-http404-container{
	transform: translate(-50%,-50%);
	position: absolute;
	top: 40%;
	left: 50%;
}
.wscn-http404 {
	position: relative;
	width: 240px;
	overflow: hidden;
	text-align: center;
	.pic-404 {
		position: relative;
		width: 240px;
		height: 200px;
		overflow: hidden;
		&__parent {
			width: 100%;
		}
	}
	.bullshit {
		position: relative;
		float: left;
		width: 100%;
		/*padding: 30px 0;*/
		padding-top: 30px;
		overflow: hidden;
		&__info {
			font-size: 20px;
			line-height: 21px;
			color: #16325C;
			margin-bottom: 30px;
			animation-name: slideUp;
			animation-duration: 0.5s;
			animation-delay: 0.2s;
			animation-fill-mode: forwards;
		}
		&__return-home {
			display: block;
			text-align: center;
			color: #3485F9;
			font-size: 16px;
			cursor: pointer;
			animation-name: slideUp;
			animation-duration: 0.5s;
			animation-delay: 0.3s;
			animation-fill-mode: forwards;
		}
		@keyframes slideUp {
			0% {
				transform: translateY(60px);
				opacity: 0;
			}
			100% {
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
}
</style>
